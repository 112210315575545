.cartWrapper{
    position: relative;
    width: 300px;
    height: 500px;
    border: #000000 dashed 2px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    background-color: #F0EDE5;
}

.cartWrapper:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.image {
    cursor: pointer;
    width: 300px;
    height: 150px;
    object-fit: contain;
}

.titleContainer {
    cursor: pointer;
    text-align: center;
}

.title {
    color: #000000;
    font-weight: bold;
    line-height: 1.875em;
    font-size: 18px;
}

.infoWrapper {
    background-color: #f5f5f5;
}

.text {
    color: #000000;
    font-weight: bold;
    line-height: 1.875em;
    font-size: 12px;
}

.button {
    bottom: 0;
    position: absolute;
    height: 50px;
    width: 296px;
    border-radius: 0;
}

.button:hover {
    border-color: #f5f5f5 !important;
}


.buttonText {
    color: #000000;
    font-weight: bold;
    line-height: 1.875em;
    font-size: 18px;
}