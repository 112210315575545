
.container {
    position: relative;
    padding: 0 14px!important;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.image {
    width: 64px!important;
    height: 64px!important;
    display: block;
}

.blockContent {
    display: flex;
    align-items: center;
}

.iconBlock {
    display: flex;
    position: absolute;
    right: 14px;
    cursor: pointer;
}

.textBlock {
    padding-left: 12px;
    display: flex;
    flex-direction: column;
}

.title {
    color: #f5f5f5;
    font-weight: bold;
    line-height: 1.41em;
    font-size: 22px;

}

@media screen and (max-width: 730px) {
    .title {
        line-height: 1.41em;
        font-size: 16px;
    }
    .image {
        width: 44px!important;
        height: 44px!important;
    }
}

@media screen and (max-width: 560px) {
    .title {
        line-height: 1.41em;
        font-size: 12px;
    }
    .image {
        width: 36px!important;
        height: 36px!important;
    }
    .blockContent {
        flex-direction: column;
        padding-left: 12px;
    }
    .iconBlock {
        position: initial;
    }
    .container {
        display: flex;
        justify-content: center;
    }
    .textBlock {
        padding: 0;
    }
}
