.cartGridWrapper {
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 16px;
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    justify-content: space-around;
    grid-gap: 32px
}
